var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getPermissionsCompleted
    ? _c(
        "div",
        { attrs: { id: "menu-parametrizacao" } },
        [
          _c(
            "vs-row",
            _vm._l(_vm.filteredMenus, function(item, index) {
              return _c(
                "vs-col",
                { key: index, attrs: { "vs-lg": "3", "vs-sm": "12" } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: item.routerName } } },
                    [
                      _c(
                        "vs-card",
                        [
                          _c(
                            "vs-row",
                            {
                              attrs: {
                                "vs-type": "flex",
                                "vs-justify": "center"
                              }
                            },
                            [
                              _c("span", { staticClass: "icon-param" }, [
                                item.configIcon
                                  ? _c("i", {
                                      staticClass: "onpoint-gear",
                                      class: item.src,
                                      staticStyle: {
                                        position: "relative",
                                        top: "-45px",
                                        left: "60px"
                                      }
                                    })
                                  : _vm._e(),
                                _c("i", {
                                  class: item.icon,
                                  staticStyle: {
                                    "font-size": "50px",
                                    color: "#4b5563"
                                  }
                                })
                              ])
                            ]
                          ),
                          _c(
                            "vs-row",
                            {
                              attrs: {
                                "vs-type": "flex",
                                "vs-justify": "center"
                              }
                            },
                            [_c("h3", [_vm._v(_vm._s(item.name))])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }